import React, { useState } from "react";

const Auth = ({ setAuthenticated }) => {
  const [fields, setFields] = useState(null);
  const [invalid, setInvalid] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (fields?.username === "bridgeit" && fields?.password === "mascot") {
      setInvalid(false);
      setAuthenticated(true);
    } else {
      setInvalid(true);
    }
  };
  return (
    <div className="auth">
      <div className="auth-inner">
        <form onSubmit={handleSubmit}>
          <h1>Bridgeit</h1>
          {invalid && <div className="error">Invalid entries, try again.</div>}
          <div className="input">
            <label htmlFor="username">Username</label>
            <input
              type="text"
              id="username"
              name="username"
              value={fields?.username || ""}
              onChange={(e) => {
                setFields((p) => ({ ...p, username: e.target.value }));
                if (invalid) setInvalid(false);
              }}
            />
            <label htmlFor="password">Password</label>
          </div>
          <div className="input">
            <input
              type="password"
              id="password"
              name="password"
              value={fields?.password || ""}
              onChange={(e) => {
                setFields((p) => ({ ...p, password: e.target.value }));
                if (invalid) setInvalid(false);
              }}
            />
          </div>
          <div className="input">
            <input type="submit" value="Submit" />
          </div>
        </form>
      </div>
    </div>
  );
};

export default Auth;
