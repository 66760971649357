import React, { useState } from "react";
import Auth from "./Auth";
import Licenses from "./Licenses";

const App = () => {
  const [authenticated, setAuthenticated] = useState(false);
  return (
    <div>
      {!authenticated ? (
        <Auth setAuthenticated={setAuthenticated} />
      ) : (
        <Licenses />
      )}
    </div>
  );
};

export default App;
