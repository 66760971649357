import React, { useState, useEffect } from "react";
import axios from "axios";
import { API_URL } from "./constants";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const Licenses = () => {
  const [licenses, setLicenses] = useState(null);
  const [error, setError] = useState(null);

  const getLicenses = () => {
    axios({
      method: "GET",
      url: `${API_URL}/licenses`,
    })
      .then((res) => {
        const { success, data } = res.data;
        if (!success) {
          setError("Something went wrong.");
          return;
        }
        setLicenses(data);
        setError(null);
      })
      .catch((err) => {
        setError("Failed to retrieve licenses");
      });
  };

  const tryAgain = () => {
    setError(null);
    getLicenses();
  };

  useEffect(() => {
    if (!licenses) getLicenses();
  }, [licenses]);

  const handleRowUpdate = (checked, expires, approved, id) => {
    axios({
      method: "PUT",
      url: `${API_URL}/license`,
      data: {
        id,
        active: checked,
        approved,
        expires,
      },
    })
      .then((res) => {
        const { success } = res.data;
        if (success) getLicenses();
      })
      .catch((err) => {
        alert("Updating failed");
      });
  };

  return (
    <div className="container">
      <div className="licenses">
        <h1>Licenses</h1>
        {error && (
          <div className="error">
            Error: {error} <br />{" "}
            <button type="button" onClick={tryAgain}>
              Try again
            </button>
          </div>
        )}
        {!licenses && !error
          ? "Loading..."
          : licenses && (
              <div className="table">
                <div className="table-inner">
                  <div className="table--heading">
                    <div className="table--heading-item">
                      <h3>Identifier</h3>
                    </div>
                    <div className="table--heading-item">
                      <h3>Mac</h3>
                    </div>
                    <div className="table--heading-item">
                      <h3>Devlock</h3>
                    </div>
                    <div className="table--heading-item">
                      <h3>Active</h3>
                    </div>
                    <div className="table--heading-item">
                      <h3>Expiry</h3>
                    </div>
                    <div className="table--heading-item">
                      <h3>Approved</h3>
                    </div>
                  </div>
                  <div className="table--content">
                    {licenses.map((license) => {
                      const {
                        id,
                        identifier,
                        mac,
                        devlock_key,
                        active,
                        expires,
                        approved,
                      } = license;
                      return (
                        <div className="table--content-row" key={id}>
                          <div className="table--content-row-item">
                            <h5>{identifier}</h5>
                          </div>
                          <div className="table--content-row-item">
                            <h5>{mac}</h5>
                          </div>
                          <div className="table--content-row-item">
                            <h5>{devlock_key}</h5>
                          </div>
                          <div className="table--content-row-item">
                            <input
                              type="checkbox"
                              id={`checkbox-${id}`}
                              checked={active === 1 ? true : false}
                              onChange={(e) =>
                                handleRowUpdate(
                                  e.target.checked,
                                  expires,
                                  approved,
                                  id
                                )
                              }
                            />
                            <label htmlFor={`checkbox-${id}`}>
                              {active ? "Active" : "Inactive"}
                            </label>
                          </div>
                          <div className="table--content-row-item">
                            <div className="input">
                              <DatePicker
                                selected={new Date(expires)}
                                onChange={(date) =>
                                  handleRowUpdate(active, date, approved, id)
                                }
                              />
                            </div>
                          </div>
                          <div className="table--content-row-item">
                            <input
                              type="checkbox"
                              id={`approved-${id}`}
                              checked={approved === 1 ? true : false}
                              onChange={(e) =>
                                handleRowUpdate(
                                  active,
                                  expires,
                                  e.target.checked,
                                  id
                                )
                              }
                            />
                            <label htmlFor={`approved-${id}`}>
                              {approved ? "Approved" : "Not approved"}
                            </label>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            )}
      </div>
    </div>
  );
};

export default Licenses;
